import { Vue, Component } from 'vue-property-decorator'
import { CreateElement } from 'vue'
import CloseSvg from '~/assets/svg/close-popup.svg?inline'
import RegionPopup from '~/components/general/popups/RegionPopup'
import ResetPasswordPopup from '~/components/general/popups/ResetPasswordPopup'
import DiseasesSectionsPopup from '~/components/general/popups/DiseasesSectionsPopup'
import PickupSelectorPopup from '~/components/general/popups/PickupSelectorPopup'
import FilterPopup from '~/components/general/popups/FilterPopup'
import OrderBasketPopup from '~/components/general/popups/OrderBasketPopup'
import CallbackPopup from '~/components/general/popups/CallbackPopup'
import OrderRatingPopup from '~/components/general/popups/OrderRatingPopup'
import WaitingListPopup from '~/components/general/popups/WaitingListPopup'
import ReviewPopup from '~/components/general/popups/ReviewPopup'
import AuthPopup from '~/components/general/popups/AuthPopup'
import OrderAuthPopup from '~/components/general/popups/OrderAuthPopup'
import SmartBannerPopup from '~/components/general/popups/SmartBannerPopup'
import EsiaAuthPopup from '~/components/general/popups/EsiaAuthPopup'
import s from '~/utils/s'
import PhoneConfirmationPopup from '~/components/general/popups/PhoneConfirmationPopup'
import ResultToCartPopup from '~/components/general/popups/ResultToCartPopup'

export enum PopupName {
  popupEsiaAuth = 'EsiaAuthPopup'
}

@Component({
  components: {
    RegionPopup,
    ResetPasswordPopup,
    DiseasesSectionsPopup,
    PickupSelectorPopup,
    FilterPopup,
    OrderBasketPopup,
    CallbackPopup,
    OrderRatingPopup,
    WaitingListPopup,
    SmartBannerPopup,
    ReviewPopup,
    AuthPopup,
    PhoneConfirmationPopup,
    OrderAuthPopup,
    EsiaAuthPopup,
    ResultToCartPopup
  }
})
export default class Popup extends Vue {
  get styles () {
    const result = {
      popup: true,
      popup_regions: false,
      'popup_reset-password': false,
      'popup_diseases-sections': false,
      'popup_pickup-selector': false,
      popup_filter: false,
      'popup_order-basket': false,
      'popup_smart-banner': false,
      'popup_waiting-list': false,
      'popup_order-rating': false,
      'popup_confirm-phone': false,
      'popup_esia-auth': false,
      'popup-result-to-cart': false
    }

    switch (s(this.$store).general.popupName) {
      case 'PickupSelectorPopup':
        result['popup_pickup-selector'] = true
        break
      case 'FilterPopup':
        result.popup_filter = true
        break
      case 'OrderBasketPopup':
        result['popup_order-basket'] = true
        break
      case 'SmartBannerPopup':
        result['popup_smart-banner'] = true
        break
      case 'WaitingListPopup':
        result['popup_waiting-list'] = true
        break
      case 'OrderRatingPopup':
        result['popup_order-rating'] = true
        break
      case 'PhoneConfirmationPopup':
        result['popup_confirm-phone'] = true
        break
      case 'ResultToCartPopup':
        result['popup-result-to-cart'] = true
        break
      case PopupName.popupEsiaAuth:
        result['popup_esia-auth'] = true
    }

    return result
  }

  closePopup(event: MouseEvent) {
    if (event) {
      event.preventDefault();
    }
    s(this.$store).general.closePopup();
  }

  popup (h: CreateElement) {
    const name = s(this.$store).general.popupName
    if (name !== null) {
      return (
        <div class={this.styles}>
          <div
            {...{
              class: 'popup__wrapper'
              // directives: [{ name: 'VueClickOutside', value: this.closePopup }]
            }}
          >
            <div class="popup__close" onClick={(event: MouseEvent) => this.closePopup(event)}>
              <CloseSvg />
            </div>
            <div class="popup__body">{h(name)}</div>
          </div>
        </div>
      )
    }
  }

  render (h: CreateElement) {
    return <transition name="popup__animation">{this.popup(h)}</transition>
  }
}
