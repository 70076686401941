import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { NuxtApp } from '@nuxt/types/app'
import ListResponse from '~/types/api/ListResponse'
import Product from '~/types/api/Product'
import ResponseGroupBuilder from '~/utils/api/ResponseGroupBuilder'
import { MainSliderItem } from '~/types/api/MainSliderItem'
import { MainSliderAdvertisementItem } from '~/types/api/MainSliderAdvertisementItem'
import DiseaseSection from '~/types/api/disease/DiseaseSection'
import config from '~/utils/config'
import s from '~/utils/s'
import { parseJwt } from '~/utils/jwt'
import Stock from '~/types/api/stock/Stock'
import RegionAndSection, { SliderOptions } from '~/types/RegionAndSection'
import { SfSection } from '~/types/api/SfSection'

@Module({
  name: 'mainPage',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _promotions: ListResponse<Product> = {
    items: [],
    count: 0
  }

  _popular: ListResponse<Product> = {
    items: [],
    count: 0
  }

  _novelties: ListResponse<Product> = {
    items: [],
    count: 0
  }

  _products: ListResponse<Product> = {
    items: [],
    count: 0
  }

  _listForSlides: ListResponse<Stock> = {
    items: [],
    count: 0
  }

  _mainSliderItems: MainSliderItem[] | null = null

  _mainSliderAdvertisementItems: MainSliderAdvertisementItem[] | null = null

  _diseaseSections: DiseaseSection[] = []

  _sections: SfSection[] = []

  @Mutation
  setPromotions (promotions: ListResponse<Product>) {
    this._promotions = promotions
  }

  @Mutation
  setPopular (popular: ListResponse<Product>) {
    this._popular = popular
  }

  @Mutation
  setNovelties (novelties: ListResponse<Product>) {
    this._novelties = novelties
  }

  @Mutation
  setMainSliderItems (items: MainSliderItem[] | null) {
    this._mainSliderItems = items
  }

  @Mutation
  setMainSliderAdvertisementItems (items: MainSliderAdvertisementItem[] | null) {
    this._mainSliderAdvertisementItems = items
  }

  @Mutation
  setDiseaseSections (sections: DiseaseSection[]) {
    this._diseaseSections = sections
  }

  @Mutation
  setProducts (products: ListResponse<Product>) {
    this._products = products
  }

  @Mutation
  setListForSlides (list: ListResponse<Stock>) {
    this._listForSlides = list
  }

  @Mutation
  setSectionsSlider (sections: SfSection[]) {
    this._sections = sections
  }

  @Action({ rawError: config.rawError })
  async getAndSetDiseaseSliderData () {
    const diseases = await this.store.$api.getDiseaseSections()
    this.setDiseaseSections(diseases.data as DiseaseSection[])
  }

  @Action({ rawError: config.rawError })
  async getAndSetStocksSlider ({ regionId, sectionId }: RegionAndSection) {
    const stocks = await this.store.$api.getStocks({
      limit: 20,
      offset: 0,
      regionId,
      sectionId,
      mainPage: true
    })
    this.setListForSlides(stocks.data as ListResponse<Stock>)
  }

  @Action({ rawError: config.rawError })
  async getAndSetPromotionsSliderData ({ regionId, sectionId, isDev }: SliderOptions) {
    const promotionProducts = await this.store.$api.getSliderProducts('promotions', {
      limit: 20,
      offset: 0,
      regionId,
      sectionId,
      mainPage: true,
      devRequest: isDev
    })
    this.setPromotions(promotionProducts.data as ListResponse<Product>)
  }

  @Action({ rawError: config.rawError })
  async getAndSetPopularSliderData ({ regionId, sectionId, isDev }: SliderOptions) {
    const popularProducts = await this.store.$api.getSliderProducts('popular', {
      limit: 20,
      offset: 0,
      regionId,
      sectionId,
      mainPage: true,
      devRequest: isDev
    })
    this.setPopular(popularProducts.data as ListResponse<Product>)
  }

  @Action({ rawError: config.rawError })
  async getAndSetNoveltiesSliderData ({ regionId, sectionId, isDev }: SliderOptions) {
    const noveltyProducts = await this.store.$api.getSliderProducts('novelties', {
      limit: 20,
      offset: 0,
      regionId,
      sectionId,
      mainPage: true,
      devRequest: isDev
    })
    this.setNovelties(noveltyProducts.data as ListResponse<Product>)
  }

  @Action({ rawError: config.rawError })
  async fillBannerData () {
    const regionId = s(this.store).regions.regionId ?? '0'
    const sectionId = s(this.store).regions.sectionId
    const result = await ResponseGroupBuilder.get([
      {
        task: this.store.$api.getMainSliderItems(regionId, sectionId),
        default: [],
        key: 'mainSliderItems'
      },
      {
        task: this.store.$api.getMainSliderAdvertisementItems(regionId, sectionId),
        default: [],
        key: 'mainSliderAdvertisementItems'
      }
    ])
    for (const [key, value] of Object.entries(result)) {
      if (value != null) {
        switch (key) {
          case 'mainSliderItems':
            this.setMainSliderItems(value as MainSliderItem[])
            break
          case 'mainSliderAdvertisementItems':
            this.setMainSliderAdvertisementItems(value as MainSliderAdvertisementItem[])
            break
        }
      }
    }
  }

  @Action({ rawError: config.rawError })
  async fillAdditionalBannerData ({ regionId, sectionId, isDev }: SliderOptions) {
    await this.getAndSetPromotionsSliderData({ regionId, sectionId, isDev })
    await this.getAndSetNoveltiesSliderData({ regionId, sectionId, isDev })
    await this.getAndSetPopularSliderData({ regionId, sectionId, isDev })
    await this.getAndSetStocksSlider({ regionId, sectionId })
    await this.getAndSetDiseaseSliderData()
  }

  @Action({ rawError: config.rawError })
  async fillData () {
    const app = this.store.$router.app as NuxtApp
    const isDev = app.context.isDev
    const regionId = s(this.store).regions.regionId ?? '0'
    const sectionId = s(this.store).regions.sectionId
    if (s(this.store).auth.refreshToken) {
      const payload = parseJwt(s(this.store).auth.refreshToken)
      if (payload.exp < Date.now() / 1000) {
        await s(this.store).auth.refreshTokenAction()
      }
    }
    await this.fillAdditionalBannerData({ regionId, sectionId, isDev })
  }

  @Action({ rawError: config.rawError })
  clearData () {
    const emptyList: ListResponse<Product> = {
      count: 0,
      items: []
    }
    this.setProducts(emptyList)
    this.setPromotions(emptyList)
    this.setPopular(emptyList)
    this.setNovelties(emptyList)
    this.setDiseaseSections([])
    this.setSectionsSlider([])
  }

  @Action({ rawError: config.rawError })
  clearBannerData () {
    this.setMainSliderItems(null)
    this.setMainSliderAdvertisementItems(null)
  }

  @Action({ rawError: config.rawError })
  async getSectionsSlider () {
    const sections = await this.store.$api.getCatalogSections(s(this.store).regions.regionId ?? '0',
        s(this.store).regions.section?.sectionId ?? '0', true)
    if (sections.code === 200 && sections.data) {
      this.setSectionsSlider(sections.data)
    }
  }

  get sections (): SfSection[] {
    return this._sections
  }

  get products (): ListResponse<Product> {
    return this._products
  }

  get listForSlides (): ListResponse<Stock> {
    return this._listForSlides
  }

  get promotions (): ListResponse<Product> {
    return this._promotions
  }

  get novelties (): ListResponse<Product> {
    return this._novelties
  }

  get popular (): ListResponse<Product> {
    return this._popular
  }

  get mainSliderItems (): MainSliderItem[] | null {
    return this._mainSliderItems
  }

  get mainSliderAdvertisementItems (): MainSliderAdvertisementItem[] | null {
    return this._mainSliderAdvertisementItems
  }

  get diseaseSections (): DiseaseSection[] {
    return this._diseaseSections
  }
}
