enum AD_FOX_CONSTANTS {
  inCatalog = 'adfox_171143632755719080',
  inBasket = 'adfox_171143674440249080',
  inOrderStep = 'adfox_17114370857749080',
  inPersonal = 'adfox_171143688108249080'
}

export enum AD_FOX_CALLBACK_CODES {
  onClose = 'onClose',
  onError = 'onError',
  onLoad = 'onLoad',
  onRender = 'onRender',
  onStub = 'onStub'
}

export default AD_FOX_CONSTANTS
