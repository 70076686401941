import { Vue, Component } from 'vue-property-decorator'
import LocationButton from './LocationButton'
import CatalogButton from './CatalogButton'
import BasketButton from './BasketButton'
import PersonalButton from './PersonalButton'
import HomeSvg from '~/assets/svg/mobile-menu-home.svg?inline'
import loc from '~/utils/loc'
import s from '~/utils/s'

@Component
export default class MobileMenu extends Vue {
  render () {
    return (
      <div class="mobile-menu">
        <div class="mobile-menu__item mobile-menu__item-home">
          <nuxt-link to={loc(this.$store, {
            name: 'index'
          })} {...{
            nativeOn: {
              click: () => s(this.$store).general.closeGeneralList()
            }
          }}>
            <HomeSvg />
          </nuxt-link>
        </div>
        <div class="mobile-menu__item">
          <LocationButton />
        </div>
        <div class="mobile-menu__item">
          <CatalogButton />
        </div>
        <div class="mobile-menu__item">
          <BasketButton />
        </div>
        <div class="mobile-menu__item">
          <PersonalButton />
        </div>
      </div>
    )
  }
}
