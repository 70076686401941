export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const SetStylesForAddBanners = () => import('../../components/setStylesForAddBanners.tsx' /* webpackChunkName: "components/set-styles-for-add-banners" */).then(c => wrapFunctional(c.default || c))
export const BlockAuthForm = () => import('../../components/block/AuthForm.tsx' /* webpackChunkName: "components/block-auth-form" */).then(c => wrapFunctional(c.default || c))
export const BlockDiseaseFilter = () => import('../../components/block/DiseaseFilter.tsx' /* webpackChunkName: "components/block-disease-filter" */).then(c => wrapFunctional(c.default || c))
export const BlockDiseaseItem = () => import('../../components/block/DiseaseItem.tsx' /* webpackChunkName: "components/block-disease-item" */).then(c => wrapFunctional(c.default || c))
export const BlockDiseaseSectionIconItem = () => import('../../components/block/DiseaseSectionIconItem.tsx' /* webpackChunkName: "components/block-disease-section-icon-item" */).then(c => wrapFunctional(c.default || c))
export const BlockDiseaseSectionIconList = () => import('../../components/block/DiseaseSectionIconList.tsx' /* webpackChunkName: "components/block-disease-section-icon-list" */).then(c => wrapFunctional(c.default || c))
export const BlockDiseaseSectionList = () => import('../../components/block/DiseaseSectionList.tsx' /* webpackChunkName: "components/block-disease-section-list" */).then(c => wrapFunctional(c.default || c))
export const BlockMainCatalogSectionsSlider = () => import('../../components/block/MainCatalogSectionsSlider.tsx' /* webpackChunkName: "components/block-main-catalog-sections-slider" */).then(c => wrapFunctional(c.default || c))
export const BlockMainDiseaseBlock = () => import('../../components/block/MainDiseaseBlock.tsx' /* webpackChunkName: "components/block-main-disease-block" */).then(c => wrapFunctional(c.default || c))
export const BlockMainInfoBlock = () => import('../../components/block/MainInfoBlock.tsx' /* webpackChunkName: "components/block-main-info-block" */).then(c => wrapFunctional(c.default || c))
export const BlockMainRegionSlider = () => import('../../components/block/MainRegionSlider.tsx' /* webpackChunkName: "components/block-main-region-slider" */).then(c => wrapFunctional(c.default || c))
export const BlockMainSlideDelivery = () => import('../../components/block/MainSlideDelivery.tsx' /* webpackChunkName: "components/block-main-slide-delivery" */).then(c => wrapFunctional(c.default || c))
export const BlockMainSlider = () => import('../../components/block/MainSlider.tsx' /* webpackChunkName: "components/block-main-slider" */).then(c => wrapFunctional(c.default || c))
export const BlockMainSliderAdvertisement = () => import('../../components/block/MainSliderAdvertisement.tsx' /* webpackChunkName: "components/block-main-slider-advertisement" */).then(c => wrapFunctional(c.default || c))
export const BlockNewsItem = () => import('../../components/block/NewsItem.tsx' /* webpackChunkName: "components/block-news-item" */).then(c => wrapFunctional(c.default || c))
export const BlockNewsList = () => import('../../components/block/NewsList.tsx' /* webpackChunkName: "components/block-news-list" */).then(c => wrapFunctional(c.default || c))
export const BlockNoReviews = () => import('../../components/block/NoReviews.tsx' /* webpackChunkName: "components/block-no-reviews" */).then(c => wrapFunctional(c.default || c))
export const BlockPartnerCardBlock = () => import('../../components/block/PartnerCardBlock.tsx' /* webpackChunkName: "components/block-partner-card-block" */).then(c => wrapFunctional(c.default || c))
export const BlockPresetBuyBlock = () => import('../../components/block/PresetBuyBlock.tsx' /* webpackChunkName: "components/block-preset-buy-block" */).then(c => wrapFunctional(c.default || c))
export const BlockProductList = () => import('../../components/block/ProductList.tsx' /* webpackChunkName: "components/block-product-list" */).then(c => wrapFunctional(c.default || c))
export const BlockProductListSmall = () => import('../../components/block/ProductListSmall.tsx' /* webpackChunkName: "components/block-product-list-small" */).then(c => wrapFunctional(c.default || c))
export const BlockProductSlides = () => import('../../components/block/ProductSlides.tsx' /* webpackChunkName: "components/block-product-slides" */).then(c => wrapFunctional(c.default || c))
export const BlockProductSlidesAnalogs = () => import('../../components/block/ProductSlidesAnalogs.tsx' /* webpackChunkName: "components/block-product-slides-analogs" */).then(c => wrapFunctional(c.default || c))
export const BlockProductSlidesSmall = () => import('../../components/block/ProductSlidesSmall.tsx' /* webpackChunkName: "components/block-product-slides-small" */).then(c => wrapFunctional(c.default || c))
export const BlockResetPassword = () => import('../../components/block/ResetPassword.tsx' /* webpackChunkName: "components/block-reset-password" */).then(c => wrapFunctional(c.default || c))
export const BlockResetPasswordData = () => import('../../components/block/ResetPasswordData.tsx' /* webpackChunkName: "components/block-reset-password-data" */).then(c => wrapFunctional(c.default || c))
export const BlockResultBlock = () => import('../../components/block/ResultBlock.tsx' /* webpackChunkName: "components/block-result-block" */).then(c => wrapFunctional(c.default || c))
export const BlockReview = () => import('../../components/block/Review.tsx' /* webpackChunkName: "components/block-review" */).then(c => wrapFunctional(c.default || c))
export const BlockSearchEntityList = () => import('../../components/block/SearchEntityList.tsx' /* webpackChunkName: "components/block-search-entity-list" */).then(c => wrapFunctional(c.default || c))
export const BlockSeoTextBlock = () => import('../../components/block/SeoTextBlock.tsx' /* webpackChunkName: "components/block-seo-text-block" */).then(c => wrapFunctional(c.default || c))
export const BlockSlider = () => import('../../components/block/Slider.tsx' /* webpackChunkName: "components/block-slider" */).then(c => wrapFunctional(c.default || c))
export const BlockStockCard = () => import('../../components/block/StockCard.tsx' /* webpackChunkName: "components/block-stock-card" */).then(c => wrapFunctional(c.default || c))
export const BlockStockItem = () => import('../../components/block/StockItem.tsx' /* webpackChunkName: "components/block-stock-item" */).then(c => wrapFunctional(c.default || c))
export const BlockStockList = () => import('../../components/block/StockList.tsx' /* webpackChunkName: "components/block-stock-list" */).then(c => wrapFunctional(c.default || c))
export const BlockStocksSlides = () => import('../../components/block/StocksSlides.tsx' /* webpackChunkName: "components/block-stocks-slides" */).then(c => wrapFunctional(c.default || c))
export const ElementBasketNumber = () => import('../../components/element/BasketNumber.tsx' /* webpackChunkName: "components/element-basket-number" */).then(c => wrapFunctional(c.default || c))
export const ElementBtn = () => import('../../components/element/Btn.tsx' /* webpackChunkName: "components/element-btn" */).then(c => wrapFunctional(c.default || c))
export const ElementCheckbox = () => import('../../components/element/Checkbox.tsx' /* webpackChunkName: "components/element-checkbox" */).then(c => wrapFunctional(c.default || c))
export const ElementCollectionProduct = () => import('../../components/element/CollectionProduct.tsx' /* webpackChunkName: "components/element-collection-product" */).then(c => wrapFunctional(c.default || c))
export const ElementCustomSelect = () => import('../../components/element/CustomSelect.tsx' /* webpackChunkName: "components/element-custom-select" */).then(c => wrapFunctional(c.default || c))
export const ElementDeliveryAddressInput = () => import('../../components/element/DeliveryAddressInput.tsx' /* webpackChunkName: "components/element-delivery-address-input" */).then(c => wrapFunctional(c.default || c))
export const ElementECertificate = () => import('../../components/element/ECertificate.tsx' /* webpackChunkName: "components/element-e-certificate" */).then(c => wrapFunctional(c.default || c))
export const ElementEsiaButton = () => import('../../components/element/EsiaButton.tsx' /* webpackChunkName: "components/element-esia-button" */).then(c => wrapFunctional(c.default || c))
export const ElementImageElement = () => import('../../components/element/ImageElement.tsx' /* webpackChunkName: "components/element-image-element" */).then(c => wrapFunctional(c.default || c))
export const ElementLinkPaginate = () => import('../../components/element/LinkPaginate.tsx' /* webpackChunkName: "components/element-link-paginate" */).then(c => wrapFunctional(c.default || c))
export const ElementLinkSelector = () => import('../../components/element/LinkSelector.tsx' /* webpackChunkName: "components/element-link-selector" */).then(c => wrapFunctional(c.default || c))
export const ElementMarkingAd = () => import('../../components/element/MarkingAd.tsx' /* webpackChunkName: "components/element-marking-ad" */).then(c => wrapFunctional(c.default || c))
export const ElementNavBackLink = () => import('../../components/element/NavBackLink.tsx' /* webpackChunkName: "components/element-nav-back-link" */).then(c => wrapFunctional(c.default || c))
export const ElementOrderSectionIcon = () => import('../../components/element/OrderSectionIcon.tsx' /* webpackChunkName: "components/element-order-section-icon" */).then(c => wrapFunctional(c.default || c))
export const ElementPagination = () => import('../../components/element/Pagination.tsx' /* webpackChunkName: "components/element-pagination" */).then(c => wrapFunctional(c.default || c))
export const ElementPreloader = () => import('../../components/element/Preloader.tsx' /* webpackChunkName: "components/element-preloader" */).then(c => wrapFunctional(c.default || c))
export const ElementPresetCard = () => import('../../components/element/PresetCard.tsx' /* webpackChunkName: "components/element-preset-card" */).then(c => wrapFunctional(c.default || c))
export const ElementPresetNumber = () => import('../../components/element/PresetNumber.tsx' /* webpackChunkName: "components/element-preset-number" */).then(c => wrapFunctional(c.default || c))
export const ElementProductBasket = () => import('../../components/element/ProductBasket.tsx' /* webpackChunkName: "components/element-product-basket" */).then(c => wrapFunctional(c.default || c))
export const ElementProductBasketForHover = () => import('../../components/element/ProductBasketForHover.tsx' /* webpackChunkName: "components/element-product-basket-for-hover" */).then(c => wrapFunctional(c.default || c))
export const ElementProductCard = () => import('../../components/element/ProductCard.tsx' /* webpackChunkName: "components/element-product-card" */).then(c => wrapFunctional(c.default || c))
export const ElementProductCardAnalogs = () => import('../../components/element/ProductCardAnalogs.tsx' /* webpackChunkName: "components/element-product-card-analogs" */).then(c => wrapFunctional(c.default || c))
export const ElementProductCardCommercialElem = () => import('../../components/element/ProductCardCommercialElem.tsx' /* webpackChunkName: "components/element-product-card-commercial-elem" */).then(c => wrapFunctional(c.default || c))
export const ElementProductOrderHistory = () => import('../../components/element/ProductOrderHistory.tsx' /* webpackChunkName: "components/element-product-order-history" */).then(c => wrapFunctional(c.default || c))
export const ElementProductSmall = () => import('../../components/element/ProductSmall.tsx' /* webpackChunkName: "components/element-product-small" */).then(c => wrapFunctional(c.default || c))
export const ElementRatingInput = () => import('../../components/element/RatingInput.tsx' /* webpackChunkName: "components/element-rating-input" */).then(c => wrapFunctional(c.default || c))
export const ElementRatingStars = () => import('../../components/element/RatingStars.tsx' /* webpackChunkName: "components/element-rating-stars" */).then(c => wrapFunctional(c.default || c))
export const ElementSelectInput = () => import('../../components/element/SelectInput.tsx' /* webpackChunkName: "components/element-select-input" */).then(c => wrapFunctional(c.default || c))
export const ElementSfImageElement = () => import('../../components/element/SfImageElement.tsx' /* webpackChunkName: "components/element-sf-image-element" */).then(c => wrapFunctional(c.default || c))
export const ElementStaticPageImageFooter = () => import('../../components/element/StaticPageImageFooter.tsx' /* webpackChunkName: "components/element-static-page-image-footer" */).then(c => wrapFunctional(c.default || c))
export const ElementSwitchElement = () => import('../../components/element/SwitchElement.tsx' /* webpackChunkName: "components/element-switch-element" */).then(c => wrapFunctional(c.default || c))
export const ElementTextInput = () => import('../../components/element/TextInput.tsx' /* webpackChunkName: "components/element-text-input" */).then(c => wrapFunctional(c.default || c))
export const ElementUserProductListNumber = () => import('../../components/element/UserProductListNumber.tsx' /* webpackChunkName: "components/element-user-product-list-number" */).then(c => wrapFunctional(c.default || c))
export const GeneralAuthorization = () => import('../../components/general/Authorization.tsx' /* webpackChunkName: "components/general-authorization" */).then(c => wrapFunctional(c.default || c))
export const GeneralChoiceCity = () => import('../../components/general/ChoiceCity.tsx' /* webpackChunkName: "components/general-choice-city" */).then(c => wrapFunctional(c.default || c))
export const GeneralLoader = () => import('../../components/general/Loader.tsx' /* webpackChunkName: "components/general-loader" */).then(c => wrapFunctional(c.default || c))
export const GeneralSmsAuthorization = () => import('../../components/general/SmsAuthorization.tsx' /* webpackChunkName: "components/general-sms-authorization" */).then(c => wrapFunctional(c.default || c))
export const GeneralTrainingScreen = () => import('../../components/general/TrainingScreen.tsx' /* webpackChunkName: "components/general-training-screen" */).then(c => wrapFunctional(c.default || c))
export const PageBreadcrumb = () => import('../../components/page/Breadcrumb.tsx' /* webpackChunkName: "components/page-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const PageMainPage = () => import('../../components/page/MainPage.tsx' /* webpackChunkName: "components/page-main-page" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../components/page/PageHeader.tsx' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const PageRegionClosed = () => import('../../components/page/RegionClosed.tsx' /* webpackChunkName: "components/page-region-closed" */).then(c => wrapFunctional(c.default || c))
export const PageSectionClosed = () => import('../../components/page/SectionClosed.tsx' /* webpackChunkName: "components/page-section-closed" */).then(c => wrapFunctional(c.default || c))
export const BlockFilterCheckoxList = () => import('../../components/block/filter/FilterCheckoxList.tsx' /* webpackChunkName: "components/block-filter-checkox-list" */).then(c => wrapFunctional(c.default || c))
export const BlockFilterProduct = () => import('../../components/block/filter/FilterProduct.tsx' /* webpackChunkName: "components/block-filter-product" */).then(c => wrapFunctional(c.default || c))
export const BlockFilterRange = () => import('../../components/block/filter/FilterRange.tsx' /* webpackChunkName: "components/block-filter-range" */).then(c => wrapFunctional(c.default || c))
export const BlockFilterRecipe = () => import('../../components/block/filter/FilterRecipe.tsx' /* webpackChunkName: "components/block-filter-recipe" */).then(c => wrapFunctional(c.default || c))
export const BlockFilterSectionChildren = () => import('../../components/block/filter/FilterSectionChildren.tsx' /* webpackChunkName: "components/block-filter-section-children" */).then(c => wrapFunctional(c.default || c))
export const BlockFilterSwitch = () => import('../../components/block/filter/FilterSwitch.tsx' /* webpackChunkName: "components/block-filter-switch" */).then(c => wrapFunctional(c.default || c))
export const BlockRegisterDataStep = () => import('../../components/block/register/DataStep.tsx' /* webpackChunkName: "components/block-register-data-step" */).then(c => wrapFunctional(c.default || c))
export const BlockRegisterPasswordStep = () => import('../../components/block/register/PasswordStep.tsx' /* webpackChunkName: "components/block-register-password-step" */).then(c => wrapFunctional(c.default || c))
export const BlockRegisterPhoneConfimStep = () => import('../../components/block/register/PhoneConfimStep.tsx' /* webpackChunkName: "components/block-register-phone-confim-step" */).then(c => wrapFunctional(c.default || c))
export const BlockRegisterPhoneStep = () => import('../../components/block/register/PhoneStep.tsx' /* webpackChunkName: "components/block-register-phone-step" */).then(c => wrapFunctional(c.default || c))
export const BlockRegisterForm = () => import('../../components/block/register/RegisterForm.tsx' /* webpackChunkName: "components/block-register-form" */).then(c => wrapFunctional(c.default || c))
export const GeneralCatalogMenu = () => import('../../components/general/catalogMenu/CatalogMenu.tsx' /* webpackChunkName: "components/general-catalog-menu" */).then(c => wrapFunctional(c.default || c))
export const GeneralCatalogMenuFirstLevelList = () => import('../../components/general/catalogMenu/FirstLevelList.tsx' /* webpackChunkName: "components/general-catalog-menu-first-level-list" */).then(c => wrapFunctional(c.default || c))
export const GeneralCatalogMenuSecondLevelList = () => import('../../components/general/catalogMenu/SecondLevelList.tsx' /* webpackChunkName: "components/general-catalog-menu-second-level-list" */).then(c => wrapFunctional(c.default || c))
export const GeneralCatalogMenuThirdLevelList = () => import('../../components/general/catalogMenu/ThirdLevelList.tsx' /* webpackChunkName: "components/general-catalog-menu-third-level-list" */).then(c => wrapFunctional(c.default || c))
export const GeneralFooter = () => import('../../components/general/footer/Footer.tsx' /* webpackChunkName: "components/general-footer" */).then(c => wrapFunctional(c.default || c))
export const GeneralFooterApps = () => import('../../components/general/footer/FooterApps.tsx' /* webpackChunkName: "components/general-footer-apps" */).then(c => wrapFunctional(c.default || c))
export const GeneralFooterCallback = () => import('../../components/general/footer/FooterCallback.tsx' /* webpackChunkName: "components/general-footer-callback" */).then(c => wrapFunctional(c.default || c))
export const GeneralFooterContact = () => import('../../components/general/footer/FooterContact.tsx' /* webpackChunkName: "components/general-footer-contact" */).then(c => wrapFunctional(c.default || c))
export const GeneralFooterCopyright = () => import('../../components/general/footer/FooterCopyright.tsx' /* webpackChunkName: "components/general-footer-copyright" */).then(c => wrapFunctional(c.default || c))
export const GeneralFooterInfo = () => import('../../components/general/footer/FooterInfo.tsx' /* webpackChunkName: "components/general-footer-info" */).then(c => wrapFunctional(c.default || c))
export const GeneralFooterLogo = () => import('../../components/general/footer/FooterLogo.tsx' /* webpackChunkName: "components/general-footer-logo" */).then(c => wrapFunctional(c.default || c))
export const GeneralFooterMenu = () => import('../../components/general/footer/FooterMenu.tsx' /* webpackChunkName: "components/general-footer-menu" */).then(c => wrapFunctional(c.default || c))
export const GeneralFooterSocial = () => import('../../components/general/footer/FooterSocial.tsx' /* webpackChunkName: "components/general-footer-social" */).then(c => wrapFunctional(c.default || c))
export const GeneralGeneralListCatalogList = () => import('../../components/general/generalList/CatalogList.tsx' /* webpackChunkName: "components/general-general-list-catalog-list" */).then(c => wrapFunctional(c.default || c))
export const GeneralList = () => import('../../components/general/generalList/GeneralList.tsx' /* webpackChunkName: "components/general-list" */).then(c => wrapFunctional(c.default || c))
export const GeneralGeneralListMenuList = () => import('../../components/general/generalList/MenuList.tsx' /* webpackChunkName: "components/general-general-list-menu-list" */).then(c => wrapFunctional(c.default || c))
export const GeneralGeneralListMobileCatagoryList = () => import('../../components/general/generalList/MobileCatagoryList.tsx' /* webpackChunkName: "components/general-general-list-mobile-catagory-list" */).then(c => wrapFunctional(c.default || c))
export const GeneralGeneralListMobilePersonalList = () => import('../../components/general/generalList/MobilePersonalList.tsx' /* webpackChunkName: "components/general-general-list-mobile-personal-list" */).then(c => wrapFunctional(c.default || c))
export const GeneralGeneralListMobileSearchList = () => import('../../components/general/generalList/MobileSearchList.tsx' /* webpackChunkName: "components/general-general-list-mobile-search-list" */).then(c => wrapFunctional(c.default || c))
export const GeneralHeaderCategoryFilterHeaderSticky = () => import('../../components/general/header/CategoryFilterHeaderSticky.tsx' /* webpackChunkName: "components/general-header-category-filter-header-sticky" */).then(c => wrapFunctional(c.default || c))
export const GeneralHeader = () => import('../../components/general/header/Header.tsx' /* webpackChunkName: "components/general-header" */).then(c => wrapFunctional(c.default || c))
export const GeneralHeaderBottom = () => import('../../components/general/header/HeaderBottom.tsx' /* webpackChunkName: "components/general-header-bottom" */).then(c => wrapFunctional(c.default || c))
export const GeneralHeaderCatalogButton = () => import('../../components/general/header/HeaderCatalogButton.tsx' /* webpackChunkName: "components/general-header-catalog-button" */).then(c => wrapFunctional(c.default || c))
export const GeneralHeaderCatalogLinks = () => import('../../components/general/header/HeaderCatalogLinks.tsx' /* webpackChunkName: "components/general-header-catalog-links" */).then(c => wrapFunctional(c.default || c))
export const GeneralHeaderCloseGenrealList = () => import('../../components/general/header/HeaderCloseGenrealList.tsx' /* webpackChunkName: "components/general-header-close-genreal-list" */).then(c => wrapFunctional(c.default || c))
export const GeneralHeaderLinks = () => import('../../components/general/header/HeaderLinks.tsx' /* webpackChunkName: "components/general-header-links" */).then(c => wrapFunctional(c.default || c))
export const GeneralHeaderLocation = () => import('../../components/general/header/HeaderLocation.tsx' /* webpackChunkName: "components/general-header-location" */).then(c => wrapFunctional(c.default || c))
export const GeneralHeaderLogo = () => import('../../components/general/header/HeaderLogo.tsx' /* webpackChunkName: "components/general-header-logo" */).then(c => wrapFunctional(c.default || c))
export const GeneralHeaderPersonal = () => import('../../components/general/header/HeaderPersonal.tsx' /* webpackChunkName: "components/general-header-personal" */).then(c => wrapFunctional(c.default || c))
export const GeneralHeaderSearch = () => import('../../components/general/header/HeaderSearch.tsx' /* webpackChunkName: "components/general-header-search" */).then(c => wrapFunctional(c.default || c))
export const GeneralHeaderSticky = () => import('../../components/general/header/HeaderSticky.tsx' /* webpackChunkName: "components/general-header-sticky" */).then(c => wrapFunctional(c.default || c))
export const GeneralHeaderTabs = () => import('../../components/general/header/HeaderTabs.tsx' /* webpackChunkName: "components/general-header-tabs" */).then(c => wrapFunctional(c.default || c))
export const GeneralMobileMenuBasketButton = () => import('../../components/general/mobileMenu/BasketButton.tsx' /* webpackChunkName: "components/general-mobile-menu-basket-button" */).then(c => wrapFunctional(c.default || c))
export const GeneralMobileMenuCatalogButton = () => import('../../components/general/mobileMenu/CatalogButton.tsx' /* webpackChunkName: "components/general-mobile-menu-catalog-button" */).then(c => wrapFunctional(c.default || c))
export const GeneralMobileMenuLocationButton = () => import('../../components/general/mobileMenu/LocationButton.tsx' /* webpackChunkName: "components/general-mobile-menu-location-button" */).then(c => wrapFunctional(c.default || c))
export const GeneralMobileMenu = () => import('../../components/general/mobileMenu/MobileMenu.tsx' /* webpackChunkName: "components/general-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const GeneralMobileMenuPersonalButton = () => import('../../components/general/mobileMenu/PersonalButton.tsx' /* webpackChunkName: "components/general-mobile-menu-personal-button" */).then(c => wrapFunctional(c.default || c))
export const GeneralNotificationItem = () => import('../../components/general/notificationList/NotificationItem.tsx' /* webpackChunkName: "components/general-notification-item" */).then(c => wrapFunctional(c.default || c))
export const GeneralNotificationList = () => import('../../components/general/notificationList/NotificationList.tsx' /* webpackChunkName: "components/general-notification-list" */).then(c => wrapFunctional(c.default || c))
export const GeneralPopupsAuthPopup = () => import('../../components/general/popups/AuthPopup.tsx' /* webpackChunkName: "components/general-popups-auth-popup" */).then(c => wrapFunctional(c.default || c))
export const GeneralPopupsCallbackPopup = () => import('../../components/general/popups/CallbackPopup.tsx' /* webpackChunkName: "components/general-popups-callback-popup" */).then(c => wrapFunctional(c.default || c))
export const GeneralPopupsDiseasesSectionsPopup = () => import('../../components/general/popups/DiseasesSectionsPopup.tsx' /* webpackChunkName: "components/general-popups-diseases-sections-popup" */).then(c => wrapFunctional(c.default || c))
export const GeneralPopupsEsiaAuthPopup = () => import('../../components/general/popups/EsiaAuthPopup.tsx' /* webpackChunkName: "components/general-popups-esia-auth-popup" */).then(c => wrapFunctional(c.default || c))
export const GeneralPopupsFilterPopup = () => import('../../components/general/popups/FilterPopup.tsx' /* webpackChunkName: "components/general-popups-filter-popup" */).then(c => wrapFunctional(c.default || c))
export const GeneralPopupsOrderAuthPopup = () => import('../../components/general/popups/OrderAuthPopup.tsx' /* webpackChunkName: "components/general-popups-order-auth-popup" */).then(c => wrapFunctional(c.default || c))
export const GeneralPopupsOrderBasketPopup = () => import('../../components/general/popups/OrderBasketPopup.tsx' /* webpackChunkName: "components/general-popups-order-basket-popup" */).then(c => wrapFunctional(c.default || c))
export const GeneralPopupsOrderRatingPopup = () => import('../../components/general/popups/OrderRatingPopup.tsx' /* webpackChunkName: "components/general-popups-order-rating-popup" */).then(c => wrapFunctional(c.default || c))
export const GeneralPopupsPhoneConfirmationPopup = () => import('../../components/general/popups/PhoneConfirmationPopup.tsx' /* webpackChunkName: "components/general-popups-phone-confirmation-popup" */).then(c => wrapFunctional(c.default || c))
export const GeneralPopupsPickupSelectorPopup = () => import('../../components/general/popups/PickupSelectorPopup.tsx' /* webpackChunkName: "components/general-popups-pickup-selector-popup" */).then(c => wrapFunctional(c.default || c))
export const GeneralPopupsPickupSelectorPopupDesktop = () => import('../../components/general/popups/PickupSelectorPopupDesktop.tsx' /* webpackChunkName: "components/general-popups-pickup-selector-popup-desktop" */).then(c => wrapFunctional(c.default || c))
export const GeneralPopupsPickupSelectorPopupMobile = () => import('../../components/general/popups/PickupSelectorPopupMobile.tsx' /* webpackChunkName: "components/general-popups-pickup-selector-popup-mobile" */).then(c => wrapFunctional(c.default || c))
export const GeneralPopupsPopup = () => import('../../components/general/popups/Popup.tsx' /* webpackChunkName: "components/general-popups-popup" */).then(c => wrapFunctional(c.default || c))
export const GeneralPopupsRegionPopup = () => import('../../components/general/popups/RegionPopup.tsx' /* webpackChunkName: "components/general-popups-region-popup" */).then(c => wrapFunctional(c.default || c))
export const GeneralPopupsResetPasswordPopup = () => import('../../components/general/popups/ResetPasswordPopup.tsx' /* webpackChunkName: "components/general-popups-reset-password-popup" */).then(c => wrapFunctional(c.default || c))
export const GeneralPopupsResultToCartPopup = () => import('../../components/general/popups/ResultToCartPopup.tsx' /* webpackChunkName: "components/general-popups-result-to-cart-popup" */).then(c => wrapFunctional(c.default || c))
export const GeneralPopupsReviewPopup = () => import('../../components/general/popups/ReviewPopup.tsx' /* webpackChunkName: "components/general-popups-review-popup" */).then(c => wrapFunctional(c.default || c))
export const GeneralPopupsSmartBannerPopup = () => import('../../components/general/popups/SmartBannerPopup.tsx' /* webpackChunkName: "components/general-popups-smart-banner-popup" */).then(c => wrapFunctional(c.default || c))
export const GeneralPopupsWaitingListPopup = () => import('../../components/general/popups/WaitingListPopup.tsx' /* webpackChunkName: "components/general-popups-waiting-list-popup" */).then(c => wrapFunctional(c.default || c))
export const PageBannerHeader = () => import('../../components/page/banner/BannerHeader.tsx' /* webpackChunkName: "components/page-banner-header" */).then(c => wrapFunctional(c.default || c))
export const PageBannerPage = () => import('../../components/page/banner/BannerPage.tsx' /* webpackChunkName: "components/page-banner-page" */).then(c => wrapFunctional(c.default || c))
export const PageBasketHeader = () => import('../../components/page/basket/BasketHeader.tsx' /* webpackChunkName: "components/page-basket-header" */).then(c => wrapFunctional(c.default || c))
export const PageBasketNotFoundPage = () => import('../../components/page/basket/BasketNotFoundPage.tsx' /* webpackChunkName: "components/page-basket-not-found-page" */).then(c => wrapFunctional(c.default || c))
export const PageBasketOnHover = () => import('../../components/page/basket/BasketOnHover.tsx' /* webpackChunkName: "components/page-basket-on-hover" */).then(c => wrapFunctional(c.default || c))
export const PageBasketPage = () => import('../../components/page/basket/BasketPage.tsx' /* webpackChunkName: "components/page-basket-page" */).then(c => wrapFunctional(c.default || c))
export const PageBasketResult = () => import('../../components/page/basket/BasketResult.tsx' /* webpackChunkName: "components/page-basket-result" */).then(c => wrapFunctional(c.default || c))
export const PageBasketScrollMobile = () => import('../../components/page/basket/BasketScrollMobile.tsx' /* webpackChunkName: "components/page-basket-scroll-mobile" */).then(c => wrapFunctional(c.default || c))
export const PageBasketWaitingListEmpty = () => import('../../components/page/basket/BasketWaitingListEmpty.tsx' /* webpackChunkName: "components/page-basket-waiting-list-empty" */).then(c => wrapFunctional(c.default || c))
export const PageCatalogSectionsHeader = () => import('../../components/page/catalogSections/CatalogSectionsHeader.tsx' /* webpackChunkName: "components/page-catalog-sections-header" */).then(c => wrapFunctional(c.default || c))
export const PageCatalogSectionsPage = () => import('../../components/page/catalogSections/CatalogSectionsPage.tsx' /* webpackChunkName: "components/page-catalog-sections-page" */).then(c => wrapFunctional(c.default || c))
export const PageCatalogSectionsFirstLevel = () => import('../../components/page/catalogSections/FirstLevel.tsx' /* webpackChunkName: "components/page-catalog-sections-first-level" */).then(c => wrapFunctional(c.default || c))
export const PageCatalogSectionsSecondLevel = () => import('../../components/page/catalogSections/SecondLevel.tsx' /* webpackChunkName: "components/page-catalog-sections-second-level" */).then(c => wrapFunctional(c.default || c))
export const PageCategoriesFilter = () => import('../../components/page/categories/CategoriesFilter.tsx' /* webpackChunkName: "components/page-categories-filter" */).then(c => wrapFunctional(c.default || c))
export const PageCategoriesHeader = () => import('../../components/page/categories/CategoriesHeader.tsx' /* webpackChunkName: "components/page-categories-header" */).then(c => wrapFunctional(c.default || c))
export const PageCategoriesPage = () => import('../../components/page/categories/CategoriesPage.tsx' /* webpackChunkName: "components/page-categories-page" */).then(c => wrapFunctional(c.default || c))
export const PageCategoriesFirstLevel = () => import('../../components/page/categories/FirstLevel.tsx' /* webpackChunkName: "components/page-categories-first-level" */).then(c => wrapFunctional(c.default || c))
export const PageCategoriesSecondLevel = () => import('../../components/page/categories/SecondLevel.tsx' /* webpackChunkName: "components/page-categories-second-level" */).then(c => wrapFunctional(c.default || c))
export const PageCategoryHeader = () => import('../../components/page/category/CategoryHeader.tsx' /* webpackChunkName: "components/page-category-header" */).then(c => wrapFunctional(c.default || c))
export const PageCategoryPage = () => import('../../components/page/category/CategoryPage.tsx' /* webpackChunkName: "components/page-category-page" */).then(c => wrapFunctional(c.default || c))
export const PageDiseaseDetailPage = () => import('../../components/page/diseaseDetail/DiseaseDetailPage.tsx' /* webpackChunkName: "components/page-disease-detail-page" */).then(c => wrapFunctional(c.default || c))
export const PageDiseasesHeader = () => import('../../components/page/diseases/DiseasesHeader.tsx' /* webpackChunkName: "components/page-diseases-header" */).then(c => wrapFunctional(c.default || c))
export const PageDiseasesPage = () => import('../../components/page/diseases/DiseasesPage.tsx' /* webpackChunkName: "components/page-diseases-page" */).then(c => wrapFunctional(c.default || c))
export const PageErrorsError404 = () => import('../../components/page/errors/Error404.tsx' /* webpackChunkName: "components/page-errors-error404" */).then(c => wrapFunctional(c.default || c))
export const PageErrorsErrorClosedSite = () => import('../../components/page/errors/ErrorClosedSite.tsx' /* webpackChunkName: "components/page-errors-error-closed-site" */).then(c => wrapFunctional(c.default || c))
export const PageErrorsUnknownError = () => import('../../components/page/errors/UnknownError.tsx' /* webpackChunkName: "components/page-errors-unknown-error" */).then(c => wrapFunctional(c.default || c))
export const PageHelpAbout = () => import('../../components/page/help/About.tsx' /* webpackChunkName: "components/page-help-about" */).then(c => wrapFunctional(c.default || c))
export const PageHelpDeliveryPayment = () => import('../../components/page/help/DeliveryPayment.tsx' /* webpackChunkName: "components/page-help-delivery-payment" */).then(c => wrapFunctional(c.default || c))
export const PageHelpHowGetOrder = () => import('../../components/page/help/HowGetOrder.tsx' /* webpackChunkName: "components/page-help-how-get-order" */).then(c => wrapFunctional(c.default || c))
export const PageHelpHowMakeOrder = () => import('../../components/page/help/HowMakeOrder.tsx' /* webpackChunkName: "components/page-help-how-make-order" */).then(c => wrapFunctional(c.default || c))
export const PageHelpPolicy = () => import('../../components/page/help/Policy.tsx' /* webpackChunkName: "components/page-help-policy" */).then(c => wrapFunctional(c.default || c))
export const PageHelpRuleService = () => import('../../components/page/help/RuleService.tsx' /* webpackChunkName: "components/page-help-rule-service" */).then(c => wrapFunctional(c.default || c))
export const PageHelpStaticPage = () => import('../../components/page/help/StaticPage.tsx' /* webpackChunkName: "components/page-help-static-page" */).then(c => wrapFunctional(c.default || c))
export const PageLoginPage = () => import('../../components/page/login/LoginPage.tsx' /* webpackChunkName: "components/page-login-page" */).then(c => wrapFunctional(c.default || c))
export const PageNewsHeader = () => import('../../components/page/news/NewsHeader.tsx' /* webpackChunkName: "components/page-news-header" */).then(c => wrapFunctional(c.default || c))
export const PageNewsPage = () => import('../../components/page/news/NewsPage.tsx' /* webpackChunkName: "components/page-news-page" */).then(c => wrapFunctional(c.default || c))
export const PageNewsDetail = () => import('../../components/page/newsDetail/NewsDetail.tsx' /* webpackChunkName: "components/page-news-detail" */).then(c => wrapFunctional(c.default || c))
export const PageNoveltiesHeader = () => import('../../components/page/novelties/NoveltiesHeader.tsx' /* webpackChunkName: "components/page-novelties-header" */).then(c => wrapFunctional(c.default || c))
export const PageNoveltiesPage = () => import('../../components/page/novelties/NoveltiesPage.tsx' /* webpackChunkName: "components/page-novelties-page" */).then(c => wrapFunctional(c.default || c))
export const PageOrderDeliverySelector = () => import('../../components/page/order/DeliverySelector.tsx' /* webpackChunkName: "components/page-order-delivery-selector" */).then(c => wrapFunctional(c.default || c))
export const PageOrderDeliveryStep = () => import('../../components/page/order/DeliveryStep.tsx' /* webpackChunkName: "components/page-order-delivery-step" */).then(c => wrapFunctional(c.default || c))
export const PageOrderIntervalSelect = () => import('../../components/page/order/IntervalSelect.tsx' /* webpackChunkName: "components/page-order-interval-select" */).then(c => wrapFunctional(c.default || c))
export const PageOrderHeader = () => import('../../components/page/order/OrderHeader.tsx' /* webpackChunkName: "components/page-order-header" */).then(c => wrapFunctional(c.default || c))
export const PageOrderInfo = () => import('../../components/page/order/OrderInfo.tsx' /* webpackChunkName: "components/page-order-info" */).then(c => wrapFunctional(c.default || c))
export const PageOrderPage = () => import('../../components/page/order/OrderPage.tsx' /* webpackChunkName: "components/page-order-page" */).then(c => wrapFunctional(c.default || c))
export const PageOrderScrollMobile = () => import('../../components/page/order/OrderScrollMobile.tsx' /* webpackChunkName: "components/page-order-scroll-mobile" */).then(c => wrapFunctional(c.default || c))
export const PageOrderStep = () => import('../../components/page/order/OrderStep.tsx' /* webpackChunkName: "components/page-order-step" */).then(c => wrapFunctional(c.default || c))
export const PageOrderStepWrapper = () => import('../../components/page/order/OrderStepWrapper.tsx' /* webpackChunkName: "components/page-order-step-wrapper" */).then(c => wrapFunctional(c.default || c))
export const PageOrderSuccessInfo = () => import('../../components/page/order/OrderSuccessInfo.tsx' /* webpackChunkName: "components/page-order-success-info" */).then(c => wrapFunctional(c.default || c))
export const PageOrderPaymentStep = () => import('../../components/page/order/PaymentStep.tsx' /* webpackChunkName: "components/page-order-payment-step" */).then(c => wrapFunctional(c.default || c))
export const PageOrderPickupStep = () => import('../../components/page/order/PickupStep.tsx' /* webpackChunkName: "components/page-order-pickup-step" */).then(c => wrapFunctional(c.default || c))
export const PageOrderSuccessStep = () => import('../../components/page/order/SuccessStep.tsx' /* webpackChunkName: "components/page-order-success-step" */).then(c => wrapFunctional(c.default || c))
export const PageOrderUserDataStep = () => import('../../components/page/order/UserDataStep.tsx' /* webpackChunkName: "components/page-order-user-data-step" */).then(c => wrapFunctional(c.default || c))
export const PageOrderDetail = () => import('../../components/page/orderDetail/OrderDetail.tsx' /* webpackChunkName: "components/page-order-detail" */).then(c => wrapFunctional(c.default || c))
export const PageOrderDetailHeader = () => import('../../components/page/orderDetail/OrderDetailHeader.tsx' /* webpackChunkName: "components/page-order-detail-header" */).then(c => wrapFunctional(c.default || c))
export const PageOrderDetailResult = () => import('../../components/page/orderDetail/OrderDetailResult.tsx' /* webpackChunkName: "components/page-order-detail-result" */).then(c => wrapFunctional(c.default || c))
export const PageOrderPaymentResult = () => import('../../components/page/orderPaymentResult/OrderPaymentResult.tsx' /* webpackChunkName: "components/page-order-payment-result" */).then(c => wrapFunctional(c.default || c))
export const PageOrderPaymentResultHeader = () => import('../../components/page/orderPaymentResult/OrderPaymentResultHeader.tsx' /* webpackChunkName: "components/page-order-payment-result-header" */).then(c => wrapFunctional(c.default || c))
export const PagePersonalHeader = () => import('../../components/page/personal/PersonalHeader.tsx' /* webpackChunkName: "components/page-personal-header" */).then(c => wrapFunctional(c.default || c))
export const PagePersonalOrders = () => import('../../components/page/personal/PersonalOrders.tsx' /* webpackChunkName: "components/page-personal-orders" */).then(c => wrapFunctional(c.default || c))
export const PagePersonalPage = () => import('../../components/page/personal/PersonalPage.tsx' /* webpackChunkName: "components/page-personal-page" */).then(c => wrapFunctional(c.default || c))
export const PagePersonalProfile = () => import('../../components/page/personal/PersonalProfile.tsx' /* webpackChunkName: "components/page-personal-profile" */).then(c => wrapFunctional(c.default || c))
export const PagePersonalRecipes = () => import('../../components/page/personal/PersonalRecipes.tsx' /* webpackChunkName: "components/page-personal-recipes" */).then(c => wrapFunctional(c.default || c))
export const PagePersonalView = () => import('../../components/page/personal/PersonalView.tsx' /* webpackChunkName: "components/page-personal-view" */).then(c => wrapFunctional(c.default || c))
export const PagePharmaciesDesktop = () => import('../../components/page/pharmacies/PharmaciesDesktop.tsx' /* webpackChunkName: "components/page-pharmacies-desktop" */).then(c => wrapFunctional(c.default || c))
export const PagePharmaciesHeader = () => import('../../components/page/pharmacies/PharmaciesHeader.tsx' /* webpackChunkName: "components/page-pharmacies-header" */).then(c => wrapFunctional(c.default || c))
export const PagePharmaciesMobile = () => import('../../components/page/pharmacies/PharmaciesMobile.tsx' /* webpackChunkName: "components/page-pharmacies-mobile" */).then(c => wrapFunctional(c.default || c))
export const PagePharmaciesPage = () => import('../../components/page/pharmacies/PharmaciesPage.tsx' /* webpackChunkName: "components/page-pharmacies-page" */).then(c => wrapFunctional(c.default || c))
export const PagePharmacyDetailHeader = () => import('../../components/page/pharmacyDetail/PharmacyDetailHeader.tsx' /* webpackChunkName: "components/page-pharmacy-detail-header" */).then(c => wrapFunctional(c.default || c))
export const PagePharmacyDetailPage = () => import('../../components/page/pharmacyDetail/PharmacyDetailPage.tsx' /* webpackChunkName: "components/page-pharmacy-detail-page" */).then(c => wrapFunctional(c.default || c))
export const PagePopularHeader = () => import('../../components/page/popular/PopularHeader.tsx' /* webpackChunkName: "components/page-popular-header" */).then(c => wrapFunctional(c.default || c))
export const PagePopularPage = () => import('../../components/page/popular/PopularPage.tsx' /* webpackChunkName: "components/page-popular-page" */).then(c => wrapFunctional(c.default || c))
export const PagePresetFixed = () => import('../../components/page/preset/PresetFixed.tsx' /* webpackChunkName: "components/page-preset-fixed" */).then(c => wrapFunctional(c.default || c))
export const PagePresetHeader = () => import('../../components/page/preset/PresetHeader.tsx' /* webpackChunkName: "components/page-preset-header" */).then(c => wrapFunctional(c.default || c))
export const PagePresetPage = () => import('../../components/page/preset/PresetPage.tsx' /* webpackChunkName: "components/page-preset-page" */).then(c => wrapFunctional(c.default || c))
export const PageProductCertificates = () => import('../../components/page/product/ProductCertificates.tsx' /* webpackChunkName: "components/page-product-certificates" */).then(c => wrapFunctional(c.default || c))
export const PageProductDeliveryInfo = () => import('../../components/page/product/ProductDeliveryInfo.tsx' /* webpackChunkName: "components/page-product-delivery-info" */).then(c => wrapFunctional(c.default || c))
export const PageProductFixed = () => import('../../components/page/product/ProductFixed.tsx' /* webpackChunkName: "components/page-product-fixed" */).then(c => wrapFunctional(c.default || c))
export const PageProductImage = () => import('../../components/page/product/ProductImage.tsx' /* webpackChunkName: "components/page-product-image" */).then(c => wrapFunctional(c.default || c))
export const PageProductInfo = () => import('../../components/page/product/ProductInfo.tsx' /* webpackChunkName: "components/page-product-info" */).then(c => wrapFunctional(c.default || c))
export const PageProductInstruction = () => import('../../components/page/product/ProductInstruction.tsx' /* webpackChunkName: "components/page-product-instruction" */).then(c => wrapFunctional(c.default || c))
export const PageProductMenu = () => import('../../components/page/product/ProductMenu.tsx' /* webpackChunkName: "components/page-product-menu" */).then(c => wrapFunctional(c.default || c))
export const PageProductPage = () => import('../../components/page/product/ProductPage.tsx' /* webpackChunkName: "components/page-product-page" */).then(c => wrapFunctional(c.default || c))
export const PageProductReviews = () => import('../../components/page/product/ProductReviews.tsx' /* webpackChunkName: "components/page-product-reviews" */).then(c => wrapFunctional(c.default || c))
export const PageReviewsHeader = () => import('../../components/page/reviews/ReviewsHeader.tsx' /* webpackChunkName: "components/page-reviews-header" */).then(c => wrapFunctional(c.default || c))
export const PageReviewsPage = () => import('../../components/page/reviews/ReviewsPage.tsx' /* webpackChunkName: "components/page-reviews-page" */).then(c => wrapFunctional(c.default || c))
export const PageReviewsSidebar = () => import('../../components/page/reviews/ReviewsSidebar.tsx' /* webpackChunkName: "components/page-reviews-sidebar" */).then(c => wrapFunctional(c.default || c))
export const PageSaleHeader = () => import('../../components/page/sale/SaleHeader.tsx' /* webpackChunkName: "components/page-sale-header" */).then(c => wrapFunctional(c.default || c))
export const PageSalePage = () => import('../../components/page/sale/SalePage.tsx' /* webpackChunkName: "components/page-sale-page" */).then(c => wrapFunctional(c.default || c))
export const PageSearchHeader = () => import('../../components/page/search/SearchHeader.tsx' /* webpackChunkName: "components/page-search-header" */).then(c => wrapFunctional(c.default || c))
export const PageSearchNotFount = () => import('../../components/page/search/SearchNotFount.tsx' /* webpackChunkName: "components/page-search-not-fount" */).then(c => wrapFunctional(c.default || c))
export const PageSearchPage = () => import('../../components/page/search/SearchPage.tsx' /* webpackChunkName: "components/page-search-page" */).then(c => wrapFunctional(c.default || c))
export const PageStockHeader = () => import('../../components/page/stock/StockHeader.tsx' /* webpackChunkName: "components/page-stock-header" */).then(c => wrapFunctional(c.default || c))
export const PageStockPage = () => import('../../components/page/stock/StockPage.tsx' /* webpackChunkName: "components/page-stock-page" */).then(c => wrapFunctional(c.default || c))
export const PageStockDetail = () => import('../../components/page/stockDetail/StockDetail.tsx' /* webpackChunkName: "components/page-stock-detail" */).then(c => wrapFunctional(c.default || c))
export const PageUserListHeaderName = () => import('../../components/page/userProductList/UserListHeaderName.tsx' /* webpackChunkName: "components/page-user-list-header-name" */).then(c => wrapFunctional(c.default || c))
export const PageUserListResult = () => import('../../components/page/userProductList/UserListResult.tsx' /* webpackChunkName: "components/page-user-list-result" */).then(c => wrapFunctional(c.default || c))
export const PageUserLists = () => import('../../components/page/userProductList/UserLists.tsx' /* webpackChunkName: "components/page-user-lists" */).then(c => wrapFunctional(c.default || c))
export const PageUserListsItem = () => import('../../components/page/userProductList/UserListsItem.tsx' /* webpackChunkName: "components/page-user-lists-item" */).then(c => wrapFunctional(c.default || c))
export const PageUserProductHeader = () => import('../../components/page/userProductList/UserProductHeader.tsx' /* webpackChunkName: "components/page-user-product-header" */).then(c => wrapFunctional(c.default || c))
export const PageUserProductListPage = () => import('../../components/page/userProductList/UserProductListPage.tsx' /* webpackChunkName: "components/page-user-product-list-page" */).then(c => wrapFunctional(c.default || c))
export const PageUserProductListWishlistUserProductList = () => import('../../components/page/userProductList/WishlistUserProductList.tsx' /* webpackChunkName: "components/page-user-product-list-wishlist-user-product-list" */).then(c => wrapFunctional(c.default || c))
export const PageWishlistHeader = () => import('../../components/page/wishlist/WishlistHeader.tsx' /* webpackChunkName: "components/page-wishlist-header" */).then(c => wrapFunctional(c.default || c))
export const PageWishlistPage = () => import('../../components/page/wishlist/WishlistPage.tsx' /* webpackChunkName: "components/page-wishlist-page" */).then(c => wrapFunctional(c.default || c))
export const GeneralPopupsOraderRatingStepsOrderRatingStepComment = () => import('../../components/general/popups/oraderRatingSteps/OrderRatingStepComment.tsx' /* webpackChunkName: "components/general-popups-orader-rating-steps-order-rating-step-comment" */).then(c => wrapFunctional(c.default || c))
export const GeneralPopupsOraderRatingStepsOrderRatingStepStars = () => import('../../components/general/popups/oraderRatingSteps/OrderRatingStepStars.tsx' /* webpackChunkName: "components/general-popups-orader-rating-steps-order-rating-step-stars" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
