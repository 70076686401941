import { Vue, Component } from 'vue-property-decorator'
import BurgerSvg from '~/assets/svg/burger.svg?inline'
import s from '~/utils/s'

@Component
export default class HeaderCatalogButton extends Vue {
  beforeMount () {
    if (this.$store.$router.currentRoute.name !== 'region-all' &&
      this.$store.$router.currentRoute.name !== 'region' &&
      this.$store.$router.currentRoute.name !== 'index') {
      s(this.$store).general.initSections()
    }
  }

  onMouseEnter () {
    this.$emit('hover', true)
  }

  onMouseLeave () {
    this.$emit('hover', false)
  }

  onMouseClick () {
    this.$emit('click')
  }

  render () {
    return (
      <div onMouseenter={this.onMouseEnter} onMouseleave={this.onMouseLeave} onclick={this.onMouseClick} class="catalog-button">
        <div class="catalog-button__logo">
          <BurgerSvg />
        </div>
        <div class="catalog-button__text">
					Каталог
        </div>
      </div>
    )
  }
}
