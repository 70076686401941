import { Component, Prop, Vue } from 'vue-property-decorator'
import { CreateElement } from 'vue'
import FirstLevelList from '~/components/general/catalogMenu/FirstLevelList'
import SecondLevelList from '~/components/general/catalogMenu/SecondLevelList'
import s from '~/utils/s'
import { SfSection } from '~/types/api/SfSection'
import general from '~/store/general'

@Component({
  components: {
    FirstLevelList,
    SecondLevelList
  }
})
export default class CatalogMenu extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly isShow!: boolean

  pSelectedChildId: string = '0';
  generalStore: general

  constructor () {
    super()
    this.generalStore = s(this.$store).general
  }

  get firstLevelList (): SfSection[] {
    return this.generalStore.sections.filter(e => !e.parentId)
  }

  get secondLevelList (): SfSection[] {
    return this.generalStore.sections.filter(e => e.parentId === this.selectedChildId)
  }

  get selectedChildId (): string {
    if (this.pSelectedChildId === '0' && this.firstLevelList.length > 1) {
      return this.firstLevelList[1].id
    }
    return this.pSelectedChildId
  }

  get isShowChild (): boolean {
    return this.secondLevelList.length > 0
  }

  render (h: CreateElement) {
    const firstLevelList = h(FirstLevelList, {
      props: {
        selectedId: this.selectedChildId,
        sections: this.firstLevelList
      },
      on: {
        select: (id: string) => (this.pSelectedChildId = id),
        close: () => this.$emit('close')
      }
    })

    const secondLevelList = h(SecondLevelList, {
      props: {
        sections: this.secondLevelList
      },
      on: {
        close: () => this.$emit('close')
      }
    })

    let list = null
    if (this.isShowChild) {
      list = (
        <div class="catalog-menu__col-second-level">
          {secondLevelList}
        </div>
      )
    }

    return (
      <div class={{
        'catalog-menu': true,
        'catalog-menu_show': this.isShow
      }}>
        <div class="catalog-menu__content">
          <div class="catalog-menu__row">
            <div class="catalog-menu__col-first-level">
              {firstLevelList}
            </div>
            {list}
          </div>
        </div>
      </div>
    )
  }
}
