import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { E_COMMERCE_OPERATIONS } from "../types/enum/ECommerce";
import config from "~/utils/config";
import { ECommerceOptions, ECommerceOrderOptions } from "~/types/api/eCommerce";

@Module({
  name: "eCommerce",
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  // #region actions
  @Action({ rawError: config.rawError })
  async addToBasketECommerce(options: ECommerceOptions) {
    const product = (
      await this.store.$api.getProduct({
        code: options.productId,
        regionId: options.regionId,
        sectionId: options.sectionId
      })
    ).data;
    window.dataLayer?.push({
      ecommerce: {
        currencyCode: "RUB",
        [options.type]: {
          products: [
            {
              id: product?.code,
              name: product?.title,
              price: product?.price,
              quantity: options.quantity ?? 1
            }
          ]
        }
      }
    });
  }

  @Action({ rawError: config.rawError })
  confirmOrderECommerce(options: ECommerceOrderOptions[]) {
    // const product = (await this.store.$api.getProduct({ code: options.productId, regionId: options.regionId, sectionId: options.sectionId })).data

    window.dataLayer.push({
      ecommerce: {
        currencyCode: "RUB",
        [E_COMMERCE_OPERATIONS.confirmOrder]: {
          actionField: {
            id: options[0].orderId
          },
          products: options.map(product => {
            return {
              id: product.productId,
              name: product.name,
              price: product.price,
              quantity: product.quantity
            };
          })
        }
      }
    });
  }
  // #endregion
}
