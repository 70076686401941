import { Store } from 'vuex'
import LinkItem from '~/types/LinkItem'
import loc from '~/utils/loc'
import s from '~/utils/s'

export function helpMenu (store: Store<any>): LinkItem[] {
  return [
    {
      title: 'Как сделать заказ',
      location: loc(store, {
        name: 'help-how-make-order'
      })
    },
    {
      title: 'Где получить заказ',
      location: loc(store, {
        name: 'help-how-get-order'
      })
    },
    {
      title: 'Доставка и оплата',
      location: loc(store, {
        name: 'help-delivery-payment'
      })
    },
    {
      title: 'Правила и условия пользования сервисом',
      location: loc(store, {
        name: 'help-rule-service'
      })
    },
    {
      title: 'Справочник заболеваний',
      location: loc(store, {
        name: 'diseases'
      })
    }
  ]
}

export function aboutMenu (store: Store<any>): LinkItem[] {
  const aboutMenuList: LinkItem[] = [
    {
      title: 'О компании',
      location: loc(store, {
        name: 'help-about'
      })
    },
    {
      title: 'Акции',
      location: loc(store, {
        name: 'stock'
      })
    },
    // {
    //   title: 'Контакты аптек',
    //   location: loc(store, {
    //     name: 'pharmacies'
    //   })
    // },
    {
      title: 'Новости',
      location: loc(store, {
        name: 'news'
      })
    }
  ]

  return aboutMenuList
}
