import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import config from '~/utils/config'
import AD_FOX_CONSTANTS from '~/types/enum/AdFoxEnum'
import { AD_FOX_CALLBACK_CODES } from '~/types/enum/AdFoxEnum'

@Module({
  name: 'advertising',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _isActive: Boolean = false
  _adfoxCallbackCode?: AD_FOX_CALLBACK_CODES = undefined
  _p1: string = ''
  // #region actions
  @Action({ rawError: config.rawError })
  async addInfoInContainer (id: string) {
    await this.checkAdvStatus()
    switch (id) {
      case AD_FOX_CONSTANTS.inCatalog:
        this.setP1('dbqlc')
        break
      case AD_FOX_CONSTANTS.inBasket:
        this.setP1('dbqld')
        break
      case AD_FOX_CONSTANTS.inOrderStep:
        this.setP1('dbqlf')
        break
      case AD_FOX_CONSTANTS.inPersonal:
        this.setP1('dbqle')
        break
    }
    if (!this.isActive) { return }  

    await this.createAdfoxCode(id)
  }

  @Action
  async createAdfoxCode (
    id: string
  ): Promise<AD_FOX_CALLBACK_CODES | undefined> {
    const self = this

    return new Promise(resolve => {
      window.yaContextCb?.push(() => {
        // eslint-disable-next-line no-undef
        Ya.adfoxCode.create({
          ownerId: 5202103,
          containerId: id,
          params: {
            p1: this.p1,
            p2: 'ixfw'
          },
          onClose: () => {
            self.setAdfoxCallbackCode(AD_FOX_CALLBACK_CODES.onClose)
            resolve(AD_FOX_CALLBACK_CODES.onClose)
          },
          onError: function(error: any) {
            self.setAdfoxCallbackCode(AD_FOX_CALLBACK_CODES.onError)
            resolve(AD_FOX_CALLBACK_CODES.onError)
          },
          onRender: function() {
            self.setAdfoxCallbackCode(AD_FOX_CALLBACK_CODES.onRender)
            resolve(AD_FOX_CALLBACK_CODES.onRender)
          },
          onStub: function() {
            self.setAdfoxCallbackCode(AD_FOX_CALLBACK_CODES.onStub)
            resolve(AD_FOX_CALLBACK_CODES.onStub)
          }
        })
      })
    })
    console.log(window.yaContextCb)
  }
  // #endregion

  @Action({ rawError: config.rawError })
  async checkAdvStatus () {
    const status = (await this.store.$api.getDpdStatus()).data
    if (status) {
      return this.setIsActive(status)
    }
  }

  @Mutation
  setIsActive (status: Boolean) {
    this._isActive = status
  }

  @Mutation
  setAdfoxCallbackCode (code: AD_FOX_CALLBACK_CODES) {
    this._adfoxCallbackCode = code
  }

  @Mutation
  setP1 (p1: string) {
    this._p1 = p1
  }

  get isActive () {
    return this._isActive
  }

  get adfoxCallbackCode (): AD_FOX_CALLBACK_CODES | undefined {
    return this._adfoxCallbackCode
  }

  get p1 (): string {
    return this._p1
  }
}
